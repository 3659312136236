import React, {useContext} from 'react'
import { Routes, Route, Link, useNavigate, useSearchParams   } from "react-router-dom";
import { UserContext } from '../Context/Context';

// Idle Timer
import { useIdleTimer } from 'react-idle-timer'

//Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setData } from '../Redux/Slice/user'
import { useSelector, useDispatch } from 'react-redux'


const IdleTimer = (props) =>{
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useSelector((state) => state.user.value)
    //instantiate navigate
    let navigate = useNavigate();

    //instantiate toast
    const notify = (message) => {
        toast(message, {
          position: toast.POSITION.TOP_CENTER,
          className:'bg-red-500 font-semibold text-center text-sm py-0 text-white',
        });
    };

    //when idle toast and redirect
    const handleOnIdle = event => {
        if(searchParams.get('mobile') == 'true'){

        }else{
          if(user.hasOwnProperty('token')){
              notify("Session Expired") 
              dispatch(setData({}))
              console.log(user.token)
              navigate("/", { replace: true })
              window.location.reload()
              // logoutIdle()
              // navigate("/login", { replace: true })
          }
        }
        // console.log('user is idle', event)
        // console.log('last active', getLastActiveTime())
      }
    
      const handleOnActive = event => {
        // console.log('user is active', event)
        // console.log('time remaining', getRemainingTime())
      }
    
      const handleOnAction = event => {
        // console.log('user did something', event)
        // localStorage.setItem("_expiredTime",Date.now() + 1000 * 3)
        localStorage.setItem("_expiredTime",Date.now() + 1000 * 60 * 30)
      }
      const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        // timeout: 1000 * 3,
        timeout: 1000 * 60 * 30,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
        crossTab: {
            emitOnAllTabs:true
        }
      })
      return (
          <div>
              {props.children}
          </div>
      )

}
export default IdleTimer