import { createContext } from "react";


export const themes = {
  dark: "dark-content",
  light: "",
};

export const user = {
  user_id: "",
};

export const ThemeContext = createContext({
    theme: themes.light, 
  changeTheme: () => {},
});

export const UserContext = createContext(null);

export const EventDetailsContext = createContext(null);