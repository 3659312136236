import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../Services";
import modal from "../../Pages/community/modal/modal";

export const getListCommunityData = createAsyncThunk("community/getListCommunity", async () => {
    var user = localStorage.getItem('persist:user');
    var jsonParseUser = JSON.parse(user).value;
    var jsonParseToken = JSON.parse(jsonParseUser)
    var url = `/api-community/get_community?user_id=${jsonParseToken.user.id}`
    const response = await instance.get(url);
    return response.data.data
})

export const getUserInformationCommunity = createAsyncThunk('community/getUserInformationCommunity', async(slug) => {
    var user = localStorage.getItem('persist:user');
    var jsonParseUser = JSON.parse(user).value;
    var jsonParseToken = JSON.parse(jsonParseUser)
    var url = `${modal.getUserInformation}?userId=${jsonParseToken.user.id}&communityCode=${slug}`
    const response = await instance.get(url);
    return response.data.user
})

export const getDetailCommunity = createAsyncThunk('community/getDetailCommunity', async(slug) => {
    var user = localStorage.getItem('persist:user');
    var jsonParseUser = JSON.parse(user).value;
    var jsonParseToken = JSON.parse(jsonParseUser)
    var url = `${modal.detailCommunity}?community_code=${slug}`
    const response = await instance.get(url)
    return response.data.data
})

export const getPointHistory = createAsyncThunk('community/getPointHistory', async ({monthId, yearId}) => {
    var user = localStorage.getItem('persist:user');
    var jsonParseUser = JSON.parse(user).value;
    var jsonParseToken = JSON.parse(jsonParseUser)
    var url = `${modal.getpointHistoryUser}?userId=${jsonParseToken.user.id}&month=${monthId}&year=${yearId}`
    const response = await instance.get(url)
    return response.data.data
})

export const getEventCommunity = createAsyncThunk('community/getEventCommunity', async({slugData, statusEvent}) => {
    var user = localStorage.getItem('persist:user');
    var jsonParseUser = JSON.parse(user).value;
    var jsonParseToken = JSON.parse(jsonParseUser)
    var url = `${modal.getEventV2}?community_code=${slugData}&status_event=${statusEvent}&userId=${jsonParseToken.user.id}`
    const response = await instance.get(url)
    return response.data.data
})

export const getEventCommunityAll = createAsyncThunk('community/getEventCommunityAll', async(slugData) => {
    var user = localStorage.getItem('persist:user');
    var jsonParseUser = JSON.parse(user).value;
    var jsonParseToken = JSON.parse(jsonParseUser)
    var url = `${modal.getEventV2}?community_code=${slugData}&status_event=all&userId=${jsonParseToken.user.id}`
    const response = await instance.get(url)
    return response.data.data
})

export const getMemberCommunity = createAsyncThunk('community/getMemberCommunity', async(slug) => {
    const url = `${modal.getMemberList}?community_code=${slug}`
    const response = await instance.get(url)
    return response.data.data
})

export const getRewardsListCommunity = createAsyncThunk('community/getRewardsListCommunity', async(slug) => {
    const url = `${modal.getRewardsListCommunity}?slug=${slug}`
    const response = await instance.get(url)
    return response.data
})

const CommunitySlice = createSlice({
    name: 'community',
    initialState : {
        community : [],
        status : 'idle',
        error: null,
        loading : false,
        userData: null,
        detailCommunity: null,
        loadingDetailCommunity: false,
        pointHistory : [],
        loadingPointHistory : true,
        eventData: [],
        eventDataAll: [],
        loadingEvent: true,
        loadingEventAll: true,
        memberList: [],
        loadMember : true,
        rewardList: [],
        loadRewards: true,
    },
    extraReducers(builder){
        builder.addCase(getListCommunityData.pending, (state, action) => {
            state.status = 'loading';
            state.loading = true;
        }).addCase(getListCommunityData.fulfilled, (state, action) => {
            state.status = 'success';
            state.community = action.payload;
            state.loading = false;
        }).addCase(getListCommunityData.rejected, (state, action) => {
            state.status = 'error'
            state.error = action.error.message;
            state.loading = false;
        }).addCase(getUserInformationCommunity.pending, (state, action) => {
            state.loading = true;
        }).addCase(getUserInformationCommunity.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = action.payload
        }).addCase(getUserInformationCommunity.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message;
        }).addCase(getDetailCommunity.pending, (state, action) => {
            state.loadingDetailCommunity = true
        }).addCase(getDetailCommunity.fulfilled, (state, action) => {
            state.loadingDetailCommunity = false
            state.detailCommunity = action.payload
        }).addCase(getDetailCommunity.rejected, (state, action) => {
            state.loadingDetailCommunity = false
        }).addCase(getPointHistory.pending, (state, action) => {
            state.loadingPointHistory = true
        }).addCase(getPointHistory.fulfilled, (state, action) => {
            state.loadingPointHistory = false
            state.pointHistory = action.payload
        }).addCase(getPointHistory.rejected, (state, action) =>{
            state.loadingPointHistory = false
            state.error = action.error.message
        }).addCase(getEventCommunity.pending, (state, action) => {
            state.loadingEvent = true
        }).addCase(getEventCommunity.fulfilled, (state, action) => {
            state.loadingEvent = false
            state.eventData = action.payload
        }).addCase(getEventCommunity.rejected, (state, action) => {
            state.loadingEvent = false
            state.error = action.error.message
        }).addCase(getEventCommunityAll.pending, (state, action) => {
            state.loadingEventAll = true
        }).addCase(getEventCommunityAll.fulfilled, (state, action) => {
            state.loadingEventAll = false
            state.eventDataAll = action.payload
        }).addCase(getEventCommunityAll.rejected, (state, action) => {
            state.loadingEventAll = false
            state.error = action.error.message
        }).addCase(getMemberCommunity.fulfilled, (state, action) => {
            state.loadMember = false
            state.memberList = action.payload
        }).addCase(getMemberCommunity.rejected, (state, action) => {
            state.loadMember = false;
            state.error = action.error.message
        }).addCase(getRewardsListCommunity.fulfilled, (state, action) => {
            state.loadRewards = false;
            state.rewardList = action.payload
        })
    }
})

const { actions, reducer } = CommunitySlice;


export default reducer;